<template>
    <div class="body">
        <ul>
            <li v-for="(item, index) in data.items" :key="index">
                <div class="image"><img :src="item.icon" /></div>
                <div class="title">{{ item.title }}</div>
                <div class="description"> {{ item.description }}</div>
            </li>
            <div class="clear"></div>
        </ul>
    </div>
</template>

<script>
export default {
  props: {
    data: Object
  },
  created() {
    console.log(this.data);
  },
  data() {
    return {};
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
.clear {
    clear: both;
}

.body {
    width: 100%;
    max-width: 1350px;
    margin: 50px auto 60px auto;
    display: block;
    word-break: break-all;
}

.image {
    height: 150px;
    width: 100%;
    margin: 0 auto;
}

.body li {
    padding: 0 4%;
    width: 23%;
    float: left;
    margin: 10px 1%;
    text-align: center;
}

.body img {
    height: 100%;
    max-width: 100%;
}

.title {
    width: 100%;
    font-size: 22px;
    color: #467ed1;
    height: 50px;
    margin: 30px 0 10px 0;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}

.description {
    width: 85%;
    margin: 0 auto;
    font-size: 14px;
    line-height: 24px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
    text-align: left;
}

@media screen and (max-width: 700px) {
    .body {
        width: 96%;
        margin: 0 auto;
    }

    .image {
        height: 26vw;
        width: 90%;
        margin: 0 auto;
    }

    .body li {
        padding: 0 4%;
        width: 45%;
        float: left;
        margin: 10px 2%;
        text-align: center;
    }

    .title {
        font-size: 4vw;
        color: #467ed1;
        line-height: 30px;
        margin: 25px 0 10px 0;
    }

    .description {
        width: 100%;
        height: 13vh;
        font-size: 3.5vw;
        line-height: 24px;
    }
}
</style>
